import '../public-path'
import Vue from 'vue'

import request from '@/util/request'
import Micro from '@gdcyy/micro-js-sdk'
import website from '@/config/website'
import router from '@/router'
import store from '@/store'
import '@/error'

import ElementUI from 'element-ui'
import '@/assets/theme/element-variables.scss'
import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'

import App from '@/App.vue'

Vue.config.productionTip = false
Vue.prototype.website = website
Vue.prototype.micro = Micro

Vue.use(ElementUI)
Vue.use(Avue, { axios: request })

// 重写 avue-crud 组件的 getTableHeight 方法
if (Micro.isMicroApp) {
  try {
    Vue.options.components['avue-crud'].options.methods.getTableHeight =
      function () {
        if (this.isAutoHeight) {
          this.$nextTick(() => {
            const tableRef = this.$refs.table
            const tablePageRef = this.$refs.tablePage
            if (!tableRef) return
            const tableStyle = tableRef.$el
            const pageStyle = tablePageRef.$el.offsetHeight || 20
            this.tableHeight =
              document.documentElement.clientHeight -
              tableStyle.offsetTop -
              pageStyle -
              this.calcHeight -
              100
          })
        } else {
          this.tableHeight = this.tableOption.height
        }
      }
  } catch (error) {
    // eslint-disable-next-line
  }
}

let app = null

/** 加载应用 */
async function mount() {
  try {
    // 先执行自动登录
    await autoLogin()

    // 登录成功后再创建和挂载 Vue 实例
    app = new Vue({ router, store, render: h => h(App) })
    app.$mount('#app')

    // 监听基座路由变化
    if (Micro.isMicroApp) {
      Micro.addDataListener(routeListener, true)
    }
  } catch (error) {
    // 登录失败时，跳转到登录页
    app = new Vue({ router, store, render: h => h(App) })
    app.$mount('#app')

    app.$router.replace('/login')
  }
}

/** 卸载应用 */
function unmount() {
  app.$destroy()
  if (app.$el) app.$el.innerHTML = ''
  app = null
}

/* 监听基座路由变化，并同步路由 */
function routeListener(data) {
  const { baseRoute } = data
  if (app && app.$route && baseRoute && app.$route.path !== baseRoute.path) {
    app.$router.push(baseRoute.path)
  }
}

/** 自动登录 */
function autoLogin() {
  return new Promise((resolve, reject) => {
    Micro.login(website.devCode)
      .then(({ code }) => {
        return store.dispatch('LoginByCode', code)
      })
      .then(resolve)
      .catch(reject)
  })
}

/** 初始化应用 */
Micro.init(mount, unmount)
